import { createStore } from 'vuex'

export default createStore({
  state: {
    VUEX_ISMOBILE: false,
    VUEX_SHARECONFIG: {}
  },
  mutations: {
    SET_ISMOBILE(state, flag){
      state.VUEX_ISMOBILE = flag
    },
    SET_SHARECONFIG(state, config){
      state.VUEX_SHARECONFIG = config
    }
  },
  actions: {
  },
  modules: {
  }
})
