import router from './router'
import getPageTitle from '@/utils/get-page-title'
router.beforeEach(async(to, from, next) => {
    
    document.title = getPageTitle(to.meta.title)
    next()
})
router.afterEach((to,from)=>{
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    window.pageYOffset = 0
})