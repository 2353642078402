import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12f52272"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "navbar"
};
import { ref, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { WOW } from 'wowjs';
import { Message, Menu } from '@element-plus/icons-vue';
export default {
  __name: 'MoHeader',
  setup(__props) {
    const drawerFlag = ref(false);
    const router = useRouter();
    const menuList = [{
      title: '首页',
      id: '1',
      url: '/'
    },
    // { title: '标准', id: '2', children: [
    //     { title: '园区数智控碳体系创建与评价标准', id: '2-1', url: '/pdf/park' },
    //     { title: '碳手印项目咨询标准', id: '2-2', url: '/pdf/consult' },
    //     { title: '温室气体自愿减排项目方法学', id: '2-3', url: '/pdf/meth' },
    //   ] 
    // },
    {
      title: '活动中心',
      id: '3',
      children: [{
        title: '最佳实践项目评级',
        id: '3-1',
        url: '/project-active'
      },
      // { title: '高峰论坛', id: '3-2', url: '' },
      {
        title: '碳手印投资',
        id: '3-3',
        url: '/invest'
      }, {
        title: '碳手印倡议',
        id: '3-4',
        url: '/initiative'
      }, {
        title: '未来城市美学赛道',
        id: '3-0',
        url: '/aiot'
      }
      // { title: '项目辅导', id: '3-5', url: '' },
      ]
    }, {
      title: '互动群体',
      id: '4',
      children: [{
        title: '碳友团',
        id: '4-1',
        url: '/carbon-friend'
      }, {
        title: '会员计划',
        id: '4-2',
        url: '/member'
      }, {
        title: '成为赋能官',
        id: '4-3',
        url: '/export'
      }]
    }, {
      title: '资源中心',
      id: '5',
      children: [{
        title: '知识库',
        id: '5-1',
        url: '/knowledge-base'
      }, {
        title: '项目库',
        id: '5-2',
        url: '/video-base'
      }, {
        title: '产品库',
        id: '5-3',
        url: '/product-base'
      }
      // { title: '服务库', id: '5-4', url: '/service-base' },
      ]
    }, {
      title: '关于我们',
      id: '6',
      url: '/about'
    }];
    onMounted(() => {
      nextTick(() => {
        new WOW({
          live: false,
          offset: 0
        }).init();
      });
    });
    const toHome = function () {
      router.push({
        path: '/'
      });
    };
    const toPage = url => {
      router.push({
        path: url
      });
      drawerFlag.value = false;
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "title fw-b",
        onClick: toHome
      }, "WELL碳手印"), _createVNode(_component_el_icon, {
        size: "0.6rem",
        color: "#fff",
        onClick: _cache[0] || (_cache[0] = $event => drawerFlag.value = true)
      }, {
        default: _withCtx(() => [_createVNode(_unref(Menu))]),
        _: 1
      })]), _createVNode(_component_el_drawer, {
        modelValue: drawerFlag.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawerFlag.value = $event),
        title: "I am the title",
        "with-header": false,
        size: "60%"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "default-active": "2",
          class: "el-menu-vertical-demo"
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuList, menu => {
            return _createElementVNode("div", {
              key: menu.id
            }, [menu.children ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 0,
              index: menu.id
            }, {
              title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(menu.title), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, menuItem => {
                return _openBlock(), _createBlock(_component_el_menu_item, {
                  index: menuItem.id,
                  onClick: $event => toPage(menuItem.url)
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(menuItem.title), 1)]),
                  _: 2
                }, 1032, ["index", "onClick"]);
              }), 256))]),
              _: 2
            }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 1,
              index: menu.id,
              onClick: $event => toPage(menu.url)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(menu.title), 1)]),
              _: 2
            }, 1032, ["index", "onClick"]))]);
          }), 64))]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};