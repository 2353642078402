import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/pdf/:code',
    name: 'Pdf',
    meta: {
      title: '标准',
    },
    component: () => import( '../views/Pdf.vue')
  },
  {
    path: '/project-active',
    name: 'ProjectActive',
    meta: {
      title: '最佳实践项目评级',
    },
    component: () => import( '../views/ProjectActive.vue')
  },
  {
    path: '/initiative',
    name: 'Initiative',
    meta: {
      title: '碳手印倡议',
    },
    component: () => import( '../views/Initiative.vue')
  },
  {
    path: '/invest',
    name: 'Invest',
    meta: {
      title: '碳手印投资',
    },
    component: () => import( '../views/Invest.vue')
  },
  {
    path: '/carbon-friend',
    name: 'CarbonFriend',
    meta: {
      title: '碳友团',
    },
    component: () => import( '../views/CarbonFriend.vue')
  },
  {
    path: '/member',
    name: 'Member',
    meta: {
      title: '会员计划',
    },
    component: () => import( '../views/Member.vue')
  },
  {
    path: '/export',
    name: 'Export',
    meta: {
      title: '成为赋能官',
    },
    component: () => import( '../views/Export.vue')
  },
  {
    path: '/video-base',
    name: 'VideoBase',
    meta: {
      title: '视频库',
    },
    component: () => import( '../views/VideoBase.vue')
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    meta: {
      title: '知识库',
    },
    component: () => import( '../views/KnowledgeBase.vue')
  },
  {
    path: '/product-base',
    name: 'ProductBase',
    meta: {
      title: '产品库',
    },
    component: () => import( '../views/ProductBase.vue')
  },
  {
    path: '/service-base',
    name: 'ServiceBase',
    meta: {
      title: '服务库',
    },
    component: () => import( '../views/ServiceBase.vue')
  },
  {
    path: '/aiot',
    name: 'Aiot',
    meta: {
      title: '未来城市美学赛道',
    },
    component: () => import( '../views/Aiot.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
