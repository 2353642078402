import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import PcHeader from './components/PcHeader';
import MoHeader from './components/MoHeader';
import Footer from './components/Footer';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import wx from 'weixin-js-sdk';
import axios from 'axios';
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const ua = window.navigator.userAgent;
    let isMobile = false;
    if (ua.indexOf('iPhone') >= 0 || ua.indexOf('Android') >= 0 || ua.indexOf('iPad') >= 0) {
      isMobile = true;
      store.commit('SET_ISMOBILE', true);
    } else {
      isMobile = false;
      store.commit('SET_ISMOBILE', false);
    }
    const getWxconig = function () {
      let url = window.location.href.split('#')[0];
      console.log(url);
      axios({
        method: 'post',
        url: 'https://service-94h4malo-1320182700.gz.apigw.tencentcs.com/release/getWxconfig',
        data: {
          url
        }
      }).then(res => {
        // console.log(res.data)
        const data = res.data;
        wx.config({
          debug: false,
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appid,
          // 必填，公众号的唯一标识
          timestamp: data.timestamp,
          // 必填，生成签名的时间戳
          nonceStr: data.noncestr,
          // 必填，生成签名的随机串
          signature: data.signature,
          // 必填，签名
          jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
        });
      });
    };

    const getShareConfig = function () {
      axios({
        method: 'get',
        url: 'https://res.carbonhp.com/assetbundles/carbon/share_config.json'
      }).then(res => {
        // console.log(res.data)
        store.commit('SET_SHARECONFIG', res.data);
      });
    };
    onMounted(() => {
      getWxconig();
      getShareConfig();
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [!_unref(isMobile) ? (_openBlock(), _createBlock(_unref(PcHeader), {
        key: 0
      })) : (_openBlock(), _createBlock(_unref(MoHeader), {
        key: 1
      })), _createVNode(_component_router_view), _createVNode(_unref(Footer))], 64);
    };
  }
};