import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "vue-progressive-image/dist/style.css"; 
// import VueVideoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'

import './permission.js'
import 'wowjs/css/libs/animate.css'
import 'lib-flexible/flexible.js'

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
